<template>
    <div class="roam_p">
        <div id="containerVr" class="roam-map"></div>
        <div class="panorama_items" v-show="panorama">
            <div class="panorama_item" v-for="(item, index) in panoramaList" :key="index">
                <img :src="item.url || '/static/images/frame/noImg.jpg'" class="title-img c-h" @click="getPoint360(item)" v-if="!item.type" />
                <img :src="renderImg(item.urlList)" class="title-img c-h" @click="getPoint360(item)" v-else />
                <p>{{ item.name }}</p>
            </div>
        </div>
        <div class="qjt_btn_list" v-show="qjt_data && qjt_data.length">
            <button v-for="(item, index) in qjt_data" v-show="item" :key="index" :class="{active: chooseIndex == index}" @click="showInfo(index,item)">
                {{ item.btnName }}
            </button>
        </div>
        <div class="qjt_btn_text" v-show="qjt_text_show">
            <div class="close">
                <div>{{ qjt_info.btnName }}</div>
                <div class="close_btn" @click="close_info">×</div>
            </div>
            <p>{{ qjt_info.btnText }}</p>
        </div>
        <div class="qjt_edit_btn" v-show="qjt_btn_show">
            <button class="tool-button" @click="spin"><img class="nav_img" src="@/assets/rotate_n.png" alt=""></button>
            <button class="tool-button" @click="lookAt360"><img class="nav_img" src="@/assets/view_control.png" alt=""></button>
            <!-- <button class="tool-button" @click="shareQJT"><img class="nav_img" src="@/assets/share_q.png" alt=""></button> -->
            <button class="tool-button" @click="showList"><img class="nav_img" src="@/assets/full_screen.png" alt=""></button>
        </div>
        <div class="lookAt" v-show="qjt_tools">
            <div class="circle"></div>
            <div class="front" @click="tool_direction('front')">前</div>
            <div class="back" @click="tool_direction('back')">后</div>
            <div class="up" @click="tool_direction('up')">上</div>
            <div class="down" @click="tool_direction('down')">下</div>
            <div class="left" @click="tool_direction('left')">左</div>
            <div class="right" @click="tool_direction('right')">右</div>
        </div>
    </div>
</template>
  
<script>
  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
  import { Toast } from 'vant';
  export default {
    props: {
        panoramaList : Array
    },
    data() {
      return {
        scene: null,
        camera: null,
        renderer: null,
        controls: null,
        bigImg: '',
        chooseIndex: null,
        singleType: true,
        // 操作按钮
        qjt_btn_show: true,
        // 文本内容的显示隐藏
        qjt_text_show: false,
        qjt_tools: false,
        qjt_data: null,
        qjt_info: {
            btnName: null,
            btnText: null,
        },
        panorama: true,
      }
    },
    methods: {
        init() {
            const toast = Toast.loading({
                message: '全景图加载中...',	//加载的时候显示的文字
                duration:0,		//展现时长(ms)，值为 0 时，toast 不会消失
                overlay:true, 	//是否显示遮罩层
                forbidClick: true	//是否禁止背景点击
            });
            // 创建渲染器
            let container = document.getElementById('containerVr');
            // 停止动画效果以及清空容器
            cancelAnimationFrame(this.render)
            container.innerHTML = ""
            this.renderer = new THREE.WebGLRenderer();
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            container.appendChild(this.renderer.domElement);
            // 创建场景
            this.scene = new THREE.Scene();
            // 创建相机
            this.camera = new THREE.PerspectiveCamera(90, container.clientWidth / container.clientHeight, 1, 1000);
            // 默认向前看
            this.camera.position.set(0, 0, -1);
            this.camera.lookAt(new THREE.Vector3(0, 0, 0))
            // 判断加载球形全景还是立方体全景: false == 球形 
            if(this.singleType === false) {
                // 创建全景图球体
                var sphereGeometry = new THREE.SphereGeometry(50, 64, 32);
                var sphereMaterial = new THREE.MeshBasicMaterial({
                    map: new THREE.TextureLoader().load(this.bigImg,function(){
                        toast.clear()
                    }),
                    side: THREE.DoubleSide
                });
                var sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
                sphereMesh.scale.set(-1, 1, 1); // 翻转球体材质
                sphereMesh.rotation.y = Math.PI / 2; // 调整球体默认方向
                this.scene.add(sphereMesh);
            } else {
                let cubeTexture = new THREE.CubeTextureLoader().load(this.bigImg,function(){
                    toast.clear()
                });
                this.scene.background = cubeTexture;
            }
            // 添加光源
            var light = new THREE.PointLight(0xffffff, 1, 0);
            light.position.set(0, 0, 0);
            this.scene.add(light);
            // 创建OrbitControls控制器
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.enableZoom = false;
            this.controls.enablePan = false;
            this.controls.minPolarAngle = 0;
            this.controls.maxPolarAngle = Math.PI;
            this.controls.enableDamping = true; // 启用惯性
            this.controls.dampingFactor = 0.05; // 惯性系数
            this.controls.rotateSpeed = 0.4; // 旋转速度
            this.controls.autoRotateSpeed = 0.3; // 旋转速度
            this.controls.autoRotate = false;
            // this.controls.zoomSpeed = 0.4;
            // 记录初始 FOV 和初始缩放比例
            const initialFov = this.camera.fov;
            const initialScale = 1;
            // 添加窗口大小监听器
            window.addEventListener('resize', this.onWindowResize, false);
        },
        renderImg(urlList) {
            if(urlList && urlList.length) {
                return urlList[0]
            } else {
                return '/static/images/frame/noImg.jpg'
            }
        },
        onWindowResize() {
            let container = document.getElementById('containerVr');
            this.camera.aspect = container.clientWidth / container.clientHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(container.clientWidth, container.clientHeight);
        },
        render() {
            requestAnimationFrame(this.render);
            this.controls.update();
            this.renderer.render(this.scene, this.camera);
        },
        async getPoint360(data) {
            
            this.singleType = data.type
            if(this.singleType === false) {
                this.bigImg = data.url
                // this.bigImg = 'http://47.98.222.207:8000/minio-netdisk/file/39002'
            }else {
                this.bigImg = data.urlList
            }
            // console.log(this.bigImg)
            this.qjt_data = data.qjtBtn
            this.init();
            this.render();
        },
        // 全景图旋转
        spin() {
            if(this.controls.autoRotate) {
                this.controls.autoRotate = false
            }else {
                this.controls.autoRotate = true
            }
        },
        // 全景图文本信息
        showInfo(index,info) {
            this.chooseIndex = index                
            this.qjt_info = info
            this.qjt_text_show = true
            this.qjt_btn_show = false
            this.qjt_tools = false
            this.panorama = false
        },
        close_info() {
            this.qjt_text_show = false
            this.qjt_btn_show = true
            this.chooseIndex = null
        },
        // shareQJT() {
        //     Toast("功能升级中...")
        //     console.log(this.controls.getAzimuthalAngle())
        //     console.log(this.controls.getPolarAngle())
        // },
        // 通过修改相机位置和看向原点操作修改相机的观察视角
        // 处在三维坐标系x轴正1位置看见原点即为向左看 --->> 以此类推
        lookAt360() {
            if(!this.qjt_tools) {
                this.qjt_tools = true
            }else {
                this.qjt_tools = false
            }
        },
        tool_direction(direction) {
            switch (direction) {
                case 'front':
                    this.camera.position.set(0, 0, -1);
                    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
                    break
                case 'back':
                    this.camera.position.set(0, 0, 1);
                    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
                    break
                case 'up':
                    this.camera.position.set(0, -1, 0);
                    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
                    break
                case 'down':
                    this.camera.position.set(0, 1, 0);
                    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
                    break
                case 'left':                        
                    this.camera.position.set(1, 0, 0);
                    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
                    break
                case 'right':
                    this.camera.position.set(-1, 0, 0);
                    this.camera.lookAt(new THREE.Vector3(0, 0, 0))
                    break
            }
        },
        showList() {
            if(!this.panorama) {
                this.panorama = true
            }else {
                this.panorama = false
            }
        }
    },
    
    mounted() {
        // console.log(this.panoramaList)
        this.getPoint360(this.panoramaList[0])
    },
    beforeDestroy() {
        cancelAnimationFrame(this.render)
        Toast.clear();
    },
    
  }
</script>
  
<style lang="less" scoped>
    .roam_p {
        width: 100%;
        height: 100%;
        .roam-map {
            width: 100%;
            height: 100%;
        }
        .panorama_items {
            position: absolute;
            bottom: 84px;
            left: 20%;
            max-width: 60%;
            left: 50%;
            transform: translate(-50%,0);
            padding: 14px;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            border-radius: 5px;
            background: rgba(0,0,0,.4);
            .panorama_item {
                position: relative;
                background: white;
                width: 90px;
                height: 90px;
                border-radius: 3px;
                margin-right: 14px;
                
                img {
                    width: 100%;
                    height: 100%;
                }
                p {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    line-height: 26px;
                    font-size: 13px;
                    text-align: center;
                    padding: 0 6px;
                    color: white;
                    background: rgba(0,0,0,.4);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .panorama_item:last-of-type {
                margin-right: 0;
            }
        }
        .qjt_btn_list {
            position: absolute;
            right: 0;
            bottom: 26px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            overflow-x: auto;
            line-height: 40px;
            color: #515151;
            z-index: 9;
            background: rgba(0,0,0,.2);
            button {
                height: 24px;
                line-height: 24px;
                font-size: 12px;
                background: none;
                border: none;
                padding: 0 16px;
                color: #ffffff;
                flex-shrink: 0;
                border-right: 1px solid rgba(255,255,255,.4);
                cursor: pointer;
                white-space: nowrap;
            }
            button:last-of-type {
                border-right: none;
            }
            button.active {
                color: #ffd03d;
            }
        }
        .qjt_btn_text {
            position: absolute;
            top: 25%;
            left: 15%;
            width: 70%;
            height: 40%;
            padding: 1rem;
            font-size: 14px;
            color: #ffffff;
            overflow-y: auto;
            border-radius: 5px;
            background: rgba(0,0,0,.3);
            .close {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                margin-bottom: 6px;
                .close_btn {
                    cursor: pointer;
                }
            }
        }
        .qjt_edit_btn {
            position: absolute;
            top: calc(50% - 50px);
            right: 14px;
            width: 32px;
            button {
                width: 32px;
                height: 32px;
                padding: 5px;
                background: rgba(0,0,0,.5);
                border: none;
                border-radius: 50%;
                margin-bottom: 8px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .lookAt {
            position: fixed;
            right: 1.2rem;
            bottom: 5.4rem;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            color: white;
            font-size: 14px;
            border: 24px solid rgba(0, 0, 0, 0.2);
            .circle {
                position: absolute;
                top: 4px;
                left: 4px;
                width: 44px;
                background: rgba(0, 0, 0, 0.2);
                height: 44px;
                border-radius: 50%;
            }
            .front {
                position: absolute;
                top: -22px;
                left: 18px;
            }
            .back {
                position: absolute;
                bottom: -22px;
                left: 18px;
            }
            .up {
                position: absolute;
                top: 7px;
                left: 18px;
            }
            .down {
                position: absolute;
                bottom: 7px;
                left: 18px;
            }
            .left {
                position: absolute;
                top: 14px;
                left: -18px;
            }
            .right {
                position: absolute;
                top: 14px;
                right: -18px;
            }
        }
        ::-webkit-scrollbar {
            /* 隐藏滚动条 */
            display: none;
        }
    }
  

</style>