import { request } from '@/libs'

/**
 * 研学漫游
 */

// 分页获取漫游列表
export function getRoam(params) {
  return request.post('/yanxue-app/research/roma/page', params)
}

// 获取漫游详情
export function getRoamById(params) {
  return request.get('/yanxue-app/research/rome', { params })
}

// 添加漫游
export function addRoam(params) {
  return request.post('/yanxue-app/research/roma', params)
}

// 修改漫游
export function updateRoam(params) {
  return request.put('/yanxue-app/research/roma', params)
}

// 删除漫游
export function deleteRoam(params) {
  return request.delete('/yanxue-app/research/roma', { params })
}