<template>
    <div class="roam-view h-100">
      <div ref="cesium" id="container" class="map-warp"></div>
      <div class="line_data_info" :style="{ top: !$route.query.isTitle ? '20px' : '100px'}">
        <div class="line_info_top">{{ detail.lineName }}</div>
          <Vgroup :labelWidth="90" class="transport">
            <Vcell label="位置：" class="bg_color">
              {{ `${province} - ${city} - ${county}` }}
            </Vcell>
            <Vcell
              label="简介："
              :value="detail.lineInfo || '暂无'"
            />
          </Vgroup>
      </div>
    </div>
  </template>
  
  <script>
  import { cesiumInit, drawTool } from "@/mixin/cesium.js";
  export default {
    props: {
        detail: { type: Object },
    },
    mixins: [cesiumInit, drawTool],
    data() {
      return {
        cesium: null,
        tool: null,
        province: null,
        city: null,
        county: null,
        color: {
          0: "rgb(57, 204, 196)",
          1: "rgb(79, 181, 255)",
          2: "rgb(255, 161, 89)",
          3: "rgb(78, 138, 255)",
          4: "#7232dd",
          5: "rgb(57, 204, 196)",
        },
        color2: {
          0: "magenta",
          1: "volcano",
          2: "orange",
          3: "cyan",
          4: "geekblue",
          5: "purple",
        },
      }
    },
    methods: {
      
    },
    async mounted() {
        this.cesiumMap(this.$refs.cesium);
        this.drawInit();
        // 漫游路线预览
        // console.log(this.detail)
        this.province = this.detail.lineAdd[1];
        this.city = this.detail.lineAdd[2];
        this.county = this.detail.lineAdd[3];
        if(this.detail.lineGpx && this.detail.lineGpx !== '' && this.detail.lineGpx != null) {
            let gpxUrl = this.detail.lineGpx
            this.gpxLayer(`${process.env.VUE_APP_BASE_API}/minio-netdisk/file/${gpxUrl}`)
        }
        this.gpxLayer(`${process.env.VUE_APP_BASE_API}/minio-netdisk/file/39229`)
    },
  }
  </script>
  
  <style lang="less" scoped>
  
  .roam-view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .map-warp {
      height: 100%;
      position: relative;
      .map_tool {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
    .line_data_info {
      position: absolute;
      top: 60px;
      left: 20px;
      width: 40%;
      color: white;
      background: rgba(0,0,0,.4);
      padding: 20px;
      .line_info_top {
        font-size: 18px;
        text-align: center;
      }
      .bg_color {
        background: rgba(24, 123, 206, 0.2);
      }
    }
  }
  </style>